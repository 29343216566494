import React from 'react';
import { NextSeo, ProductJsonLdProps } from 'next-seo';

import { correctMime } from '../../utils/file';
import { getStrapiMedia } from '../../utils/media';
import { PrevNextRel } from 'corporate-types';

interface SeoProps {
  metadata: {
    metaTitle: string;
    metaDescription: string;
    locale: string;
    locales: string[];
    shareImage: {
      data: {
        id: string | number;
        attributes: {
          alternativeText?: string;
          mime: string;
          url: string;
          formats?: any;
        };
      };
    };
    socials: {
      twitter: string;
      images: string[];
    };
    productSchema: ProductJsonLdProps;
    prevPageLink?: string;
    nextPageLink?: string;
  };
}

const Seo: React.FC<SeoProps> = ({ metadata }: SeoProps) => {
  // Prevent errors if no metadata was set
  if (!metadata) return null;

  const relPrevNextLinktags: { rel: string; href: string }[] = [];
  if (metadata?.prevPageLink)
    relPrevNextLinktags.push({
      rel: PrevNextRel.PREV,
      href: metadata?.prevPageLink,
    });
  if (metadata?.nextPageLink)
    relPrevNextLinktags.push({
      rel: PrevNextRel.NEXT,
      href: metadata?.nextPageLink,
    });

  const images = [
    {
      url: getStrapiMedia(metadata?.shareImage?.data?.attributes?.url),
      width: metadata?.shareImage?.data?.attributes?.formats?.width || 0,
      height: metadata?.shareImage?.data?.attributes?.formats?.height || 0,
      type: metadata?.shareImage?.data?.attributes?.mime,
      alt: metadata?.shareImage?.data?.attributes?.alternativeText,
    },
  ];

  const siteVerification = [];

  if (process?.env?.GOOGLE_VERIFICATION) {
    siteVerification.push({
      name: 'google-site-verification',
      content: process.env.GOOGLE_VERIFICATION,
    });
  }

  if (process?.env?.FACEBOOK_VERIFICATION) {
    siteVerification.push({
      name: 'facebook-domain-verification',
      content: process.env.FACEBOOK_VERIFICATION,
    });
  }

  const twitter = {
    handle: metadata.socials.twitter,
  };

  if (
    metadata.productSchema &&
    metadata.productSchema.images &&
    metadata.productSchema.images.length > 0
  ) {
    // TODO: move to getProductPageConfiguration function
    images.unshift({
      url: getStrapiMedia(metadata.productSchema.images[0]),
      width: '780px',
      height: '780px',
      alt: metadata.productSchema.productName,
      type: correctMime(metadata.productSchema.images[0]),
    });
  }

  let preconnect: any[] = [];
  let dnsPrefetch: any[] = [];

  if (process?.env?.PRECONNECT_ARRAY) {
    preconnect = process?.env?.PRECONNECT_ARRAY.split(',').map(
      (link: string) => {
        return {
          rel: 'preconnect',
          href: link,
        };
      }
    );

    dnsPrefetch = process?.env?.PRECONNECT_ARRAY.split(',').map(
      (link: string) => {
        return {
          rel: 'dns-prefetch',
          href: link,
        };
      }
    );
  }

  let dateTime = new Date();
  const offset = dateTime?.getTimezoneOffset?.();
  dateTime = new Date(dateTime?.getTime() - (offset || 0) * 60 * 1000);

  return (
    <>
      <NextSeo
        title={metadata?.metaTitle}
        description={metadata?.metaDescription}
        openGraph={
          {
            // Title and description are mandatory
            title: metadata?.metaTitle,
            description: metadata?.metaDescription,
            type: 'website',
            url: process?.env?.PUBLIC_URL,
            locale: metadata?.locale,
            site_name: process?.env?.NEXT_PUBLIC_COMPANY_NAME,
            // Only include OG image if we have it
            // Careful: if you disable image optimization in Strapi, this will break
            images,
          } as any
        }
        twitter={twitter}
        additionalMetaTags={siteVerification}
        additionalLinkTags={[
          ...preconnect,
          ...dnsPrefetch,
          ...relPrevNextLinktags,
        ]}
        noindex={
          (process.env.NEXT_PUBLIC_ENV_NO_INDEX_FOLLOW || '').toLowerCase() ===
          'true'
            ? true
            : false
        }
        nofollow={
          (process.env.NEXT_PUBLIC_ENV_NO_INDEX_FOLLOW || '').toLowerCase() ===
          'true'
            ? true
            : false
        }
      />
      {dateTime && dateTime?.toISOString?.().split?.('T')?.[0] && (
        <time dateTime={dateTime?.toISOString?.().split?.('T')?.[0]}></time>
      )}
    </>
  );
};

export default Seo;
