export const correctMime = (url: string) => {
  const extArray = url.split('.');
  const ext = extArray[extArray.length - 1];

  const extToMimes: any = {
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    svg: 'image/svg+xml',
  };

  if (extToMimes[ext]) {
    return extToMimes[ext];
  } else {
    return 'image/*'; // Its wrong, but its a fallback
  }
};
